import React from "react";

const Resume = () => {
  return (
    <div id="resume">
      <div className="container" id="head">
        <div className="small">
          <div className="page-title">
            <div className="social">
              <ul>
                <li className="linkedin">
                  <a
                    id="linkedin_link"
                    href="https://www.linkedin.com/in/grjmclean/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Graham McLean LinkedIn"
                  ></a>
                </li>
                <li className="email">
                  <a
                    id="mailto_link"
                    href="mailto:grahamrjmclean@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Email Graham McLean"
                  ></a>
                </li>
              </ul>
            </div>
            <h1 className="name-left">Graham McLean</h1>
            <h2 className="title-left">Product Manager</h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="small">
          <div className="column">
            <div className="left">
              {/* <div className="label">About Me</div> */}
            </div>
            <div className="right">
              <div className="resume">
                <div className="label">Experience</div>
                <ul>
                  <li>
                    <div className="date">
                      July 2019 to <span className="now"><i>now</i></span>
                    </div>
                    <div className="role">Now: Product Lead</div>
                    <div className="role2">
                      Journey: BA -&gt; Product Owner -&gt; Product Manager -&gt;
                      Product Lead
                    </div>
                    <div className="company" id="fispan">
                      <a
                        id="fispan_link"
                        href="http://www.fispan.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        FISPAN
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="date">Spring 2016, 2017, and 2018</div>
                    <div className="role">Staff Accountant, Multiple Co-ops</div>
                    <div className="role2">
                      US/Canadian Tax, Private Enterprise, Personal Tax
                    </div>
                    <div className="company" id="mnp">
                      <a
                        id="mnp_link"
                        href="https://www.mnp.ca/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        MNP LLP
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="date">2013 to 2017</div>
                    <div className="role">Member Services Representative</div>
                    <div className="role2">"Bank Teller"</div>
                    <div className="company" id="coastcapitalsavings">
                      <a
                        id="coastcapitalsavings_link"
                        href="https://www.coastcapitalsavings.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Coast Capital Savings
                      </a>
                    </div>
                  </li>
                </ul>
                <div className="label">Education</div>
                <ul>
                  <li>
                    <div className="date">Bachelor's Degree</div>
                    <div className="role">
                      BBA with Honours, Certificate in Business Technology
                      Management, and Co-op Education
                    </div>
                    <div className="company" id="beedie">
                      <a
                        id="beedie_link"
                        href="https://beedie.sfu.ca/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Beedie School of Business, SFU
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="date">Study Abroad</div>
                    <div className="role">Maastricht, Netherlands</div>
                    <div className="company" id="maastrichtuniversity">
                      <a
                        id="maastrichtuniversity_link"
                        href="https://www.maastrichtuniversity.nl/about-um/faculties/school-business-and-economics"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        School of Business and Economics, Maastricht University
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="bottom" id="bottom">
            <div className="label">Projects</div>
            <div className="projects">
              <a href="projects/fullBlackScreen/index.html">
                <p className="project-item">Full Black screen</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
