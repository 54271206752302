// src/RideTheBus.js
import React, { useState, useEffect } from 'react';
import { db } from './firebase'; // Import Firestore instance
import { collection, addDoc, Timestamp } from 'firebase/firestore'; // Import Firestore functions
import { Helmet } from 'react-helmet'; // Sets page title 
import CardDisplay from './components/CardDisplay';  // Adjust path as needed


// To Do
// - Update it so that "11" is Jack, etc 

function RideTheBus() {
  const [currentCard, setCurrentCard] = useState(null);
  const [previousCard, setPreviousCard] = useState(null);  // Track previous card for comparison
  const [firstCard, setFirstCard] = useState(null);  // Rd 3: first card
  const [secondCard, setSecondCard] = useState(null);  // Rd 3: second card
  const [deck, setDeck] = useState(shuffleDeck());
  const [round, setRound] = useState(1);
  const [guessCounter, setGuessCounter] = useState(0);
  const [drinkCounter, setdrinkCounter] = useState(0);
  const [actionLog, setActionLog] = useState([]);  // Holds the log of actions
  const [currentRoundCards, setCurrentRoundCards] = useState([]);  // Holds current cards
  const [name, setName] = useState(() => localStorage.getItem('playerName') || '');  // Retrieve from local storage
  const [loopDeck, setLoopDeck] = useState(() => JSON.parse(localStorage.getItem('loopDeck')) || false); // Retrieve loopDeck state

  // Save name to local storage when it changes
  useEffect(() => {
    localStorage.setItem('playerName', name);
  }, [name]);

  // Save loopDeck state to local storage when it changes
  useEffect(() => {
    localStorage.setItem('loopDeck', JSON.stringify(loopDeck));
  }, [loopDeck]);

  // Function to shuffle the deck
  function shuffleDeck() {
    const suits = ['hearts', 'diamonds', 'clubs', 'spades'];
    const values = Array.from({ length: 13 }, (_, i) => i + 1);
    let deck = [];
    for (let suit of suits) {
      for (let value of values) {
        let display;
        switch (value) {
          case 1:
            display = "Ace (1)";
            break;
          case 11:
            display = "Jack";
            break;
          case 12:
            display = "Queen";
            break;
          case 13:
            display = "King";
            break;
          default:
            display = value;
        }

        deck.push({ suit, value, display });
      }
    }

    // Shuffle the deck
    for (let i = deck.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [deck[i], deck[j]] = [deck[j], deck[i]];
    }
    return deck;
  }

  async function saveToDatabase(name, guessCount, drinkCount) {
    console.log("Attemping to write to database...")
    // console.log(name, guessCount, drinkCount)
    try {
      await addDoc(collection(db, 'games'), {
        name: name,
        guessCount: guessCounter + 1, // Incremented earlier
        drinkCount: drinkCounter,
        timestamp: Timestamp.now()
      });
      console.log("Written to db: ", name, guessCount + 1, drinkCount, Timestamp.now())
    } catch (e) {
      console.error("Error adding document: ", e);
      alert("Failed to save your game data. Please try again.");
    }
  }


  function backToRound1() {
    setdrinkCounter(drinkCounter + 1);
    setCurrentRoundCards([]);  // Clears the current cards
    drawCard();  // Draw a new card for round 1 again
    setRound(1);
    // Add the separator line in the log (after incorrect guess)
    setActionLog(prevLog => [...prevLog, "-----"]);
  }

  // Function to draw a card from the deck
  function drawCard() {
    if (deck.length === 0) {
      if (loopDeck) {
        // Shuffle the deck and then draw a card
        setRound(1);
        setDeck(shuffleDeck());
        setActionLog(prevLog => [...prevLog, "*****"]);
        setActionLog(prevLog => [...prevLog, "Round reset - Deck reshuffled!"]);
        setActionLog(prevLog => [...prevLog, "*****"]);
        return; // Exit this draw attempt, card draw will happen on the next call
      } else {
        alert("No more cards in the deck! Game over.");
        return null;
      }
    }

    const newCard = deck[0];
    console.log("Card:", newCard);  // This logs the new card correctly after the shuffle
    setDeck(deck.slice(1));  // Remove the drawn card from the deck
    setCurrentCard(newCard); // Set the current card
    return newCard;
  }

  // Function to attempt a card draw after the deck has been reshuffled
  // eslint-disable-next-line
  useEffect(() => {
    if (deck.length > 0 && !currentCard) {
      drawCard();
    }
    // eslint-disable-next-line
  }, [deck]);

  // Example for incorrect guess in handlecolourGuess
  function handlecolourGuess(guess) {
    if (!currentCard) return;

    const isRed = currentCard.suit === 'hearts' || currentCard.suit === 'diamonds';
    const correctGuess = (guess === 'red' && isRed) || (guess === 'black' && !isRed);

    setGuessCounter(guessCounter + 1);

    // Log the action
    const guessResult = correctGuess ? 'Correct' : 'Incorrect. Drink!';
    const newLogEntry = `${name}: Guessed ${guess}, Card: ${currentCard.display} of ${currentCard.suit} - ${guessResult}`;
    setActionLog([...actionLog, newLogEntry]);

    if (correctGuess) {
      // Move to Round 2
      setCurrentRoundCards([...currentRoundCards, currentCard]);
      setPreviousCard(currentCard);
      setCurrentCard(null);
      setRound(2);
      drawCard();
    } else {
      // Add "Incorrect" to log an d then call backToRound1 to add separator
      backToRound1();  // Reset round and draw a new card
    }
  }

  // Handler for higher/lower guess (Round 2)
  function handleHigherLowerGuess(guess) {
    if (!currentCard) return;

    const higherGuess = guess === 'higher';
    const lowerGuess = guess === 'lower';

    const correctGuess = (higherGuess && currentCard.value > previousCard.value) ||
      (lowerGuess && currentCard.value < previousCard.value);

    setGuessCounter(guessCounter + 1);

    // Log the action
    const guessResult = correctGuess ? 'Correct' : 'Incorrect. Drink!';
    const newLogEntry = `${name}: Guessed ${guess}, Card: ${currentCard.display} of ${currentCard.suit} - ${guessResult}`;
    setActionLog([...actionLog, newLogEntry]);

    if (correctGuess) {
      // setPreviousCard(currentCard);  // Store current card as previous
      setFirstCard(previousCard); // Set in lower end of the inside/outside
      setSecondCard(currentCard); // Set the higher end of the inside/outside
      setCurrentRoundCards([...currentRoundCards, currentCard]);
      setCurrentCard(null);          // Clear current card for the next round
      setRound(3);                   // Move to round 3 or next phase
      drawCard();
    } else {
      // Add "Incorrect" to log an d then call backToRound1 to add separator
      backToRound1();  // Reset round and draw a new card
    }
  }

  function handleInsideOutsideGuess(guess) {
    // handle inside outside guess for round 3
    if (!currentCard) return;

    const minCardValue = Math.min(firstCard.value, secondCard.value);
    const maxCardValue = Math.max(firstCard.value, secondCard.value);

    const correctGuess = (guess === 'inside' && currentCard.value > minCardValue && currentCard.value < maxCardValue) ||
      (guess === 'outside' && (currentCard.value < minCardValue || currentCard.value > maxCardValue));

    setGuessCounter(guessCounter + 1);

    // Log the action
    const guessResult = correctGuess ? 'Correct' : 'Incorrect. Drink!';
    const newLogEntry = `${name}: Guessed ${guess}, Card: ${currentCard.display} of ${currentCard.suit} - ${guessResult}`;
    setActionLog([...actionLog, newLogEntry]);

    if (correctGuess) {
      // Implement logic for moving to next round here
      setRound(4);
      setCurrentRoundCards([...currentRoundCards, currentCard]);
      drawCard();
    } else {
      // Add "Incorrect" to log an d then call backToRound1 to add separator
      backToRound1();  // Reset round and draw a new card
    }
  }

  // Handler for suit guess (Round 4)
  function handleSuitGuess(guess) {
    if (!currentCard) return;

    const correctGuess = currentCard.suit === guess;
    setGuessCounter(guessCounter + 1);

    // Log the action
    const guessResult = correctGuess ? 'Correct' : 'Incorrect. Drink!';
    const newLogEntry = `${name}: Guessed ${guess}, Card: ${currentCard.display} of ${currentCard.suit} - ${guessResult}`;
    setActionLog([...actionLog, newLogEntry]);

    if (correctGuess) {
      // Alert final score and reset the game
      saveToDatabase(name, guessCounter, drinkCounter)
      alert(`${name} - You've completed the game! In ${guessCounter + 1} guesses and ${drinkCounter} drinks!`);
      resetGame(); // Reset the game after alert
    } else {
      // Add "Incorrect" to log an d then call backToRound1 to add separator
      backToRound1();  // Reset round and draw a new card
    }
  }


  // Resets the game to the initial state
  function resetGame() {
    setRound(1);
    setDeck(shuffleDeck());
    setCurrentCard(null);
    setPreviousCard(null);  // Reset previous card
    setCurrentRoundCards([]);
    setGuessCounter(0);
    setdrinkCounter(0);
    setActionLog([]);  // Reset the action log
  }

  return (
    <div className="rtb">
      <Helmet>
        <title>Ride The Bus</title>
      </Helmet>
      {/* Permanent Reset Button */}
      <button onClick={resetGame}>Reset Game</button>

      <h1 className="rtb">Ride the Bus</h1>

      {/* Input for Name */}
      <div>
        <label>Name: </label>
        <input
          type="text"
          className="rtb"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter your name"
        />
      </div>

      {/* Checkbox for Loop through Deck */}
      <div>
        <label>
          <input
            type="checkbox"
            checked={loopDeck}
            onChange={() => setLoopDeck(!loopDeck)}
          />
          Loop through Deck
        </label>
      </div>

      <div className="current-info">
        <p>Current Round: {round}</p>
        <p>Total Guesses: {guessCounter}</p>
        <p>Drinks: {drinkCounter}</p>

        {/* Display the Current Round Details */}
        <div className="rtb-current-cards">
          <div><h2 className="rtb">Current Cards</h2></div>
          <div className="current-cards-section">
          <ul className="rtb">
            {currentRoundCards.map((card, index) => (
              <li key={index} className="current-cards">
                <div className="card-image-wrapper">
                  <CardDisplay card={card} />
                </div>
              </li>
            ))}
          </ul>
          </div>
        </div>


      </div>


      {/* Draw a card if no card is currently drawn */}
      {!currentCard && (round === 1 || round === 2) && (
        <button onClick={drawCard}>Draw a Card</button>
      )}

      {/* Round 1: Guess the colour (Red or Black) */}
      {round === 1 && currentCard && (
        <div>
          <button onClick={() => handlecolourGuess('red')}>Red</button>
          <button onClick={() => handlecolourGuess('black')}>Black</button>
        </div>
      )}

      {/* Round 2: Guess Higher or Lower */}
      {round === 2 && currentCard && (
        <div>
          <button onClick={() => handleHigherLowerGuess('higher')}>Higher</button>
          <button onClick={() => handleHigherLowerGuess('lower')}>Lower</button>
        </div>
      )}

      {/* Round 3: Guess Inside or Outside */}
      {round === 3 && currentCard && (
        <div>
          <button onClick={() => handleInsideOutsideGuess('inside')}>Inside</button>
          <button onClick={() => handleInsideOutsideGuess('outside')}>Outside</button>
        </div>
      )}

      {/* Round 4: Guess the suit */}
      {round === 4 && currentCard && (
        <div>
          <button onClick={() => handleSuitGuess('hearts')}>Hearts</button>
          <button onClick={() => handleSuitGuess('diamonds')}>Diamonds</button><br></br>
          <button onClick={() => handleSuitGuess('clubs')}>Clubs</button>
          <button onClick={() => handleSuitGuess('spades')}>Spades</button>
        </div>
      )}


      {/* Display the Action Log */}
      <div className="rtb-action-log">
        <h2 className="rtb">Action Log</h2>
        <ul className="rtb">
          {actionLog.map((log, index) => (
            <li key={index}>{log}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default RideTheBus;
