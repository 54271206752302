// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RideTheBus from './RideTheBus';
import Resume from './Resume'
import FullBlackScreen from './FullBlackScreen.js'
import './App.css';
import './assets/datadog.js';


function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Welcome />} /> */}
        <Route path="/" element={<Resume />} />
        <Route path="/ride-the-bus" element={<RideTheBus />} />
        <Route path="/fbs" element={<FullBlackScreen />} />

      </Routes>
    </Router>
  );
}

export default App;
