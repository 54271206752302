// src/assets/poker-cards/cardImages.js

const cardImages = {
    hearts: {
      1: require('./hearts-1.svg').default,
      2: require('./hearts-2.svg').default,
      3: require('./hearts-3.svg').default,
      4: require('./hearts-4.svg').default,
      5: require('./hearts-5.svg').default,
      6: require('./hearts-6.svg').default,
      7: require('./hearts-7.svg').default,
      8: require('./hearts-8.svg').default,
      9: require('./hearts-9.svg').default,
      10: require('./hearts-10.svg').default,
      11: require('./hearts-11.svg').default,
      12: require('./hearts-12.svg').default,
      13: require('./hearts-13.svg').default,
    },
    diamonds: {
        1: require('./diamonds-1.svg').default,
        2: require('./diamonds-2.svg').default,
        3: require('./diamonds-3.svg').default,
        4: require('./diamonds-4.svg').default,
        5: require('./diamonds-5.svg').default,
        6: require('./diamonds-6.svg').default,
        7: require('./diamonds-7.svg').default,
        8: require('./diamonds-8.svg').default,
        9: require('./diamonds-9.svg').default,
        10: require('./diamonds-10.svg').default,
        11: require('./diamonds-11.svg').default,
        12: require('./diamonds-12.svg').default,
        13: require('./diamonds-13.svg').default,
    },
    clubs: {
        1: require('./clubs-1.svg').default,
        2: require('./clubs-2.svg').default,
        3: require('./clubs-3.svg').default,
        4: require('./clubs-4.svg').default,
        5: require('./clubs-5.svg').default,
        6: require('./clubs-6.svg').default,
        7: require('./clubs-7.svg').default,
        8: require('./clubs-8.svg').default,
        9: require('./clubs-9.svg').default,
        10: require('./clubs-10.svg').default,
        11: require('./clubs-11.svg').default,
        12: require('./clubs-12.svg').default,
        13: require('./clubs-13.svg').default,
    },
    spades: {
        1: require('./spades-1.svg').default,
        2: require('./spades-2.svg').default,
        3: require('./spades-3.svg').default,
        4: require('./spades-4.svg').default,
        5: require('./spades-5.svg').default,
        6: require('./spades-6.svg').default,
        7: require('./spades-7.svg').default,
        8: require('./spades-8.svg').default,
        9: require('./spades-9.svg').default,
        10: require('./spades-10.svg').default,
        11: require('./spades-11.svg').default,
        12: require('./spades-12.svg').default,
        13: require('./spades-13.svg').default,
    },
  };
  
  export default cardImages;
  