try {
    (function(h, o, u, n, d) {
        h = h[d] = h[d] || {
            q: [],
            onReady: function(c) {
                h.q.push(c)
            }
        }
        d = o.createElement(u);
        d.async = 1;
        d.src = n
        n = o.getElementsByTagName(u)[0];
        n.parentNode.insertBefore(d, n)
    })(window, document, 'script', 'https://www.datadoghq-browser-agent.com/us3/v5/datadog-rum.js', 'DD_RUM')
    window.DD_RUM.onReady(function() {
        window.DD_RUM.init({
            clientToken: 'pub37bae248828367a90c6a2c2377110928',
            applicationId: '2cdfd372-80d3-471f-af83-1fc1b4e1d82a',
            site: 'us3.datadoghq.com',
            service: 'graham-mclean-dot-com',
            env: 'resume',
            version: '1.0.0',
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'allow',
        });
    })
    console.log('Datadog loaded successfully.')
} catch (error) {
    console.log('Datadog failed to load: ', error)
}