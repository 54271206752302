import React, { useEffect, useRef, useState } from "react";
// import './assets/styles-fbs.css';

const FullBlackScreen = () => {

  useEffect(() => {
    import('./assets/styles-fbs.css');
  }, []);

  const toggleFullscreenButtonRef = useRef(null);
  const clockContainerRef = useRef(null);
  const timeRef = useRef(null);
  const dateRef = useRef(null);

  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    // Function to handle toggling fullscreen mode
    const toggleFullscreen = () => {
      const clockContainer = clockContainerRef.current;
      const toggleFullscreenButton = toggleFullscreenButtonRef.current;

      if (!isFullscreen) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        }
        toggleFullscreenButton.textContent = 'Exit Fullscreen';
        clockContainer.style.display = 'block'; // Ensure clock and date are visible
        clockContainer.style.position = 'absolute'; // Move clock to bottom left
        clockContainer.style.bottom = '10px';
        clockContainer.style.left = '10px';
        toggleFullscreenButton.style.position = 'absolute'; // Move button to bottom left
        toggleFullscreenButton.style.bottom = '0px';
        toggleFullscreenButton.style.left = 'calc(10px + 20%)'; // Place button next to clock
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        toggleFullscreenButton.textContent = 'Toggle Fullscreen';
        clockContainer.style.display = 'flex'; // Unhide clock and date
        clockContainer.style.position = 'static'; // Reset clock position
        toggleFullscreenButton.style.position = 'static'; // Reset button position
      }
      setIsFullscreen(!isFullscreen);
    };

    // Event listener for the button click
    const toggleFullscreenButton = toggleFullscreenButtonRef.current;
    toggleFullscreenButton.addEventListener('click', toggleFullscreen);

    // Update time every second
    const updateTime = () => {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      const date = now.toDateString();

      if (timeRef.current && dateRef.current) {
        timeRef.current.textContent = `${hours}:${minutes}:${seconds}`;
        dateRef.current.textContent = date;
      }
    };

    // Call updateTime every second
    const interval = setInterval(updateTime, 1000);
    updateTime(); // Initial call to display time immediately

    // Cleanup function to remove event listener and clear interval
    return () => {
      toggleFullscreenButton.removeEventListener('click', toggleFullscreen);
      clearInterval(interval);
    };
  }, [isFullscreen]);

  return (
    <div>
      <div className="container">
        <div className="centered">
          <button ref={toggleFullscreenButtonRef} id="toggleFullscreen">Toggle Fullscreen</button>
        </div>

        <div ref={clockContainerRef} className="clock">
          <span ref={timeRef} id="time"></span>
          <span ref={dateRef} id="date"></span>
        </div>
      </div>
    </div>
  );
};

export default FullBlackScreen;
