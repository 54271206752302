// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC0uFvigWv2aqSJdgfuHhd_9Ihw-ZRTyRI",
    authDomain: "ride-the-bus-6a5a7.firebaseapp.com",
    projectId: "ride-the-bus-6a5a7",
    storageBucket: "ride-the-bus-6a5a7.appspot.com",
    messagingSenderId: "1084920015765",
    appId: "1:1084920015765:web:3beee3fb5f07e6fa76a49c"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
