import React from 'react';
import cardImages from '../assets/poker-cards/cardImages'; // Import the card image map

const CardDisplay = ({ card }) => {
  if (!card || !card.suit || !card.value) {
    return null; // Don't render anything if the card, suit, or value is missing
  }

  // Get the image path from the cardImages object
  const imagePath = cardImages[card.suit][card.value];

  return (
    <div className="card-display">
      <img 
        src={imagePath} 
        alt={`${card.value} of ${card.suit}`} 
        className="card-image" 
      />
      {/* Remove text if it's already handled elsewhere */}
    </div>
  );
}

export default CardDisplay;
